<template>

  <div v-if="!currentUser" class="bg-white text-center">
    <div class="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:py-8 lg:px-8">
    </div>
  </div>

  <div class="bg-white overflow-hidden mt-10">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl text-center">
        Notion block builder
      </h3>
      <p class="mt-3 text-lg text-gray-500 text-center">
        Build your productivity block and embed it on your Notion app for free.
      </p>

      <div v-if="!currentUser" class="mt-3 px-2 py-2 bg-gray-100 rounded-lg md:py-2 md:px-2 lg:py-2 lg:px-2 text-center">
        <div class="flex justify-between">
          <p class="text-md leading-6 text-gray-600 flex-grow">
            This <strong>free</strong> version does not store data in Kairo's database, but it keeps the data in the browser.
            <router-link
                    to="signup"
                    class="underline hidden">
              Sign up and subscribe to keep your data, edit blocks anytime and generate reports.</router-link>
          </p>
        </div>
      </div>

      <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center py-10" id="block-builder">
        <div class="lg:col-start-1 rounded-lg shadow-xl border">
          <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">

            <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_size_hours" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Type
                </label>
              </div>
              <div class="sm:col-span-3">
                <select v-model="type" id="block_type" name="block_type" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option v-for="option in typeOptions" :value="option.value" v-bind:key="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>

            <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_title" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Title
                </label>
              </div>
              <div class="sm:col-span-3">
                <input v-model="title" type="text" name="block_title" id="block_title" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
              </div>
            </div>

            <div v-if="type==='Block'" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_size_hours" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Hours
                </label>
              </div>
              <div class="sm:col-span-3">
                <select v-model="blockSizeHours" id="block_size_hours" name="block_size_hours" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option v-for="option in sizeOptionsHours" :value="option.value" v-bind:key="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="type==='Block'" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_size_minutes" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Minutes
                </label>
              </div>
              <div class="sm:col-span-3">
                <select v-model="blockSizeMinutes" id="block_size_minutes" name="block_size_minutes" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option v-for="option in sizeOptionsMinutes" :value="option.value" v-bind:key="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="type==='BlockCountdown'" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_minutes" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Minutes
                </label>
              </div>
              <div class="sm:col-span-3">
                <input v-model="blockSizeMinutes" type="number" min="0" max="59" name="block_minutes" id="block_minutes" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
              </div>
            </div>

            <div v-if="type==='BlockCountdown'" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_seconds" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Seconds
                </label>
              </div>
              <div class="sm:col-span-3">
                <select v-model="blockSizeSeconds" id="block_seconds" name="block_size_minutes" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option v-for="option in sizeOptionsSeconds" :value="option.value" v-bind:key="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="type==='BlockCounter'" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_value" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Increase by
                </label>
              </div>
              <div class="sm:col-span-3">
                <input v-model="increaseValue" type="number" min="0" name="block_value" id="block_value" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
              </div>
            </div>

            <div v-if="type==='BlockCounter'" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_size" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Goal
                </label>
              </div>
              <div class="sm:col-span-3">
                <input v-model="size" type="number" min="0" name="block_size" id="block_size" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
              </div>
            </div>

            <div v-if="type==='BlockProgress'" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_start" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Start
                </label>
              </div>
              <div class="sm:col-span-3">
                <litepie-datepicker
                        as-single
                        ref="litePieDatePicker"
                        :formatter="formatter"
                        v-model="progressStartCal"
                        id="block_start"
                />
              </div>
            </div>

            <div v-if="type==='BlockProgress'" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_end" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  End
                </label>
              </div>
              <div class="sm:col-span-3">
                <litepie-datepicker
                        as-single
                        ref="litePieDatePicker"
                        :formatter="formatter"
                        v-model="progressEndCal"
                        id="block_end"
                />
              </div>
            </div>

            <!-- Counters -->
            <div v-if="type==='BlockProgress'" class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
              <div>
                <h3 class="text-sm font-medium text-gray-900">
                  Counters
                </h3>
              </div>
              <div class="sm:col-span-3">

                <div class="py-2 align-middle inline-block min-w-full">
                  <div class="shadow border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Title
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Type
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Value
                        </th>
                        <th scope="col" class="relative px-6 py-3">
                          <span class="sr-only">Actions</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="bg-white" v-for="(counter, index) in counters" v-bind:key="counter">
                        <td class="px-6 py-4 text-sm font-medium text-gray-900 w-6 relative">
                          <div class="h-6 w-6">
                            <BlockIcon
                                    v-bind:type="counter.icon"
                                    v-bind:isPreview="true"
                                    v-bind:color="color"
                                    @click="counterChangeIconOpen(index)"
                            ></BlockIcon>
                          </div>
                          <div v-if="iconEdited === index" class="absolute bg-white w-72 shadow rounded-md z-50">
                            <div v-for="faceOption in icons" v-bind:key="faceOption.value"
                                 class="inline-block relative rounded-md h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                                 :class="'bg-' + color + '-50 hover:bg-' + color + '-700 focus:ring-' + color + '-500'"
                                 @click="counterChangeIcon(index, faceOption.value)"
                            >
                              <BlockIcon
                                      v-bind:type="faceOption.value"
                                      v-bind:isPreview="true"
                                      v-bind:color="color"
                              ></BlockIcon>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <input v-model="counter.title" type="text" name="counter_title" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <select v-model="counter.type" name="counter_type" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <option v-for="counterType in counterTypes" :value="counterType.value" v-bind:key="counterType.value">
                              {{ counterType.text }}
                            </option>
                          </select>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <input v-model="counter.day" type="text" name="counter_title" class="block w-20 shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a @click="counterDelete(index)" class="cursor-pointer text-indigo-600 hover:text-indigo-900">Delete</a>
                        </td>
                      </tr>
                      <tr class="bg-white">
                        <td class="px-6 py-4 text-sm font-medium text-gray-900 w-6 relative">
                          <div class="h-6 w-6">
                            <BlockIcon
                                    v-bind:type="counterNew.icon"
                                    v-bind:isPreview="true"
                                    v-bind:color="color"
                                    @click="counterChangeIconOpen('new')"
                            ></BlockIcon>
                          </div>
                          <div v-if="iconEdited === 'new'" class="absolute bg-white w-72 shadow rounded-md z-50">
                            <div v-for="faceOption in icons" v-bind:key="faceOption.value"
                                 class="inline-block relative rounded-md h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                                 :class="'bg-' + color + '-50 hover:bg-' + color + '-700 focus:ring-' + color + '-500'"
                                 @click="counterChangeIcon('new', faceOption.value)"
                            >
                              <BlockIcon
                                      v-bind:type="faceOption.value"
                                      v-bind:isPreview="true"
                                      v-bind:color="color"
                              ></BlockIcon>
                            </div>
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          <input v-model="counterNew.title" type="text" name="counter_title" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <select v-model="counterNew.type" name="counter_type" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            <option v-for="counterType in counterTypes" :value="counterType.value" v-bind:key="counterType.value">
                              {{ counterType.text }}
                            </option>
                          </select>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <input v-model="counterNew.day" type="text" name="counter_title" class="block w-20 shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a @click="counterAdd()" class="cursor-pointer text-indigo-600 hover:text-indigo-900">Add</a>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
              <div>
                <label for="block_title" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                  Dark mode
                </label>
              </div>
              <div class="sm:col-span-3">
                <div class="flex mx-2 text-xs">
                  <button @click="darkMode = !darkMode" type="button"
                          class="mt-1 bg-gray-200 relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          :class="{'bg-indigo-600': darkMode}"
                          role="switch" aria-checked="false">
                    <span class="sr-only">Use setting</span>
                    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                    <span class="translate-x-0 pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          :class="{'translate-x-4': darkMode}"
                    >
                      <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            aria-hidden="true"
                            :class="{'opacity-100 ease-in duration-200': !darkMode}"
                      >
                        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </span>
                      <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            :class="{'opacity-100 ease-in duration-200': darkMode}"
                            aria-hidden="true">
                        <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>
              </div>

            </div>

            <!-- Colors -->
            <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
              <div>
                <h3 class="text-sm font-medium text-gray-900">
                  Color
                </h3>
              </div>
              <div class="sm:col-span-3">
                <template v-for="colorOption in colors" v-bind:key="colorOption.value">
                  <div v-if="limitFilter('colors', colorOption.value)"
                       class="inline-block rounded-full h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                       :class="'bg-' + colorOption.value + '-400 hover:bg-' + colorOption.value + '-700 focus:ring-' + colorOption.value + '-500'"
                       @click="blockChangeColor(colorOption.value)"
                  >
                    <svg v-if="colorOption.value === color" className="inline-block h-6 w-6 rounded-full" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                  </div>
                  <div v-else
                       class="inline-block opacity-60 cursor-not-allowed rounded-full h-10 w-10 m-2 cursor-block focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                       :class="'bg-' + colorOption.value + '-400 focus:ring-' + colorOption.value + '-500'"
                  >
                    <svg class="w-6 h-6 mt-1.5 mx-auto" :class="'text-' + colorOption.value + '-900'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </div>
                </template>
              </div>
            </div>

            <!-- Faces -->
            <div v-if="type==='Block'" class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
              <div>
                <h3 class="text-sm font-medium text-gray-900">
                  Face
                </h3>
              </div>
              <div class="sm:col-span-3 grid grid-cols-4">

                <template v-for="faceOption in faces" v-bind:key="faceOption.value">
                  <div v-if="limitFilter('faces', faceOption.value)"
                       class="inline-block rounded-full h-12 w-12 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                       :class="'bg-' + color + '-400 hover:bg-' + color + '-700 focus:ring-' + color + '-500'"
                       @click="blockChangeFace(faceOption.value)"
                  >
                    <BlockFace
                      v-bind:type="faceOption.value"
                      v-bind:isActive="true"
                      v-bind:isPreview="true"
                      v-bind:color="color"
                      v-bind:skin="skin"
                    ></BlockFace>
                    <svg v-if="faceOption.value === faceType" class="h-6 w-6 mx-auto" className="inline-block h-2 w-2 rounded-full" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                  </div>
                  <div v-else
                       class="inline-block opacity-60 rounded-full h-12 w-12 m-2 cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                       :class="'bg-' + color + '-400 hover:bg-' + color + '-700 focus:ring-' + color + '-500'"
                  >
                    <BlockFace
                      v-bind:type="faceOption.value"
                      v-bind:isActive="true"
                      v-bind:isPreview="true"
                      v-bind:color="color"
                      v-bind:skin="skin"
                    ></BlockFace>
                    <svg class="w-6 h-6 mt-1.5 mx-auto" :class="'text-' + color + '-900'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </div>
                </template>
              </div>
            </div>

            <!-- Skins -->
            <div v-if="type === 'Block' && faceType === 'humanbear'" class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
              <div>
                <h3 class="text-sm font-medium text-gray-900">
                  Skin tone
                </h3>
              </div>
              <div class="sm:col-span-2">
                <div v-for="skinOption in skins" v-bind:key="skinOption.value"
                     class="inline-block rounded-full h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                     :style="'background-color: #' + skinOption.colors[50]"
                     @click="blockChangeSkin(skinOption.value)"
                >
                  <svg v-if="skinOption.value === skin" className="inline-block h-6 w-6 rounded-full" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                </div>
              </div>
            </div>

            <!-- Icons -->
            <div v-if="type==='BlockCountdown' || type==='BlockCounter'" class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
              <div>
                <h3 class="text-sm font-medium text-gray-900">
                  Icon
                </h3>
              </div>
              <div class="sm:col-span-3">
                <div v-for="faceOption in icons" v-bind:key="faceOption.value"
                     class="inline-block relative rounded-md h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                     :class="'bg-' + color + '-50 hover:bg-' + color + '-700 focus:ring-' + color + '-500'"
                     @click="blockChangeFace(faceOption.value)"
                >
                  <BlockIcon
                          v-bind:type="faceOption.value"
                          v-bind:isPreview="true"
                          v-bind:color="color"
                  ></BlockIcon>
                  <svg v-if="faceOption.value === faceType" class="rounded-full absolute top-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                </div>
              </div>
            </div>
            <div class="text-sm uppercase text-gray-600 text-center p-1">
              Unlock all options. Sign up for the free trial.
            </div>
          </div>
        </div>
        <div class="mt-10 relative lg:mt-0 lg:col-start-2 p-2 bg-white rounded-md" :class="{'embed-dark-mode' : darkMode}">
          <div class="max-w-sm mx-auto">
            <div class="mb-4">
              <h2 class="text-md font-bold tracking-tight text-gray-400 sm:text-md">Sponsored by ❤️</h2>
              <a href="https://inlinehelp.com?utm_source=kairo&utm_medium=banner&utm_campaign=kairo-notion-blocks&utm_id=kairo-notion-blocks" title="Inline Help Customer AI Support"
                class="border rounded p-2 block hover:bg-yellow-50" target="_blank"
                >
                <div class="flex items-center border-b pb-2 mb-2">
                  <img class="cursor-pointer block lg:block h-10 w-auto rounded-full" src="@/assets/sponsors/inline-help-logo.jpeg"/>
                  <div class="ml-2">
                    <h2 class="text-lg font-bold text-gray-700 sm:text-lg">Inline Help</h2>
                    <h3 class="text-sm text-gray-500">Answer customer questions before they ask</h3>
                  </div>
                </div>
                <div>
                  <p class="text-xs text-gray-600 mt-1">
                    Connect your <span class="font-bold">Notion Knowledge Base</span>. Inline Help is an AI-powered customer support tool that helps you to provide better customer support.
                    <span class="text-xs text-blue-400 mt-1">Try it now at inlinehelp.com</span>.
                  </p>
                </div>
              </a>
            </div>
            <div class="text-xs text-gray-400 text-center uppercase">Preview</div>
            <component
              :is="type || 'Block'"
              :key="id"
              :title="title"
              :size="size"
              :color="color"
              :skin="skin"
              :faceType="faceType"
              :increaseValue="increaseValue"
              :elapsedTime="0"
              :totalTime="0"
              :activeSince="activeSince"
              :activeCountdownSince="0"
              :elements="[]"
              :counters="counters"
              :progressStart="progressStart"
              :progressEnd="progressEnd"
              :blockTick="blockTick"
              :embed="embed"
              :embedPreview="embedPreview"
              :embedOptions="embedOptions"
            />
            <div class="mt-5">
              <div class="sm:col-span-2">
                <div class="max-w-lg flex rounded-md shadow-sm relative">
                  <input disabled v-model="embedURL" id="embedURL" type="text" name="embedURL" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 bg-gray-50 rounded-none rounded-l-md sm:text-sm border-gray-300">
                  <button @click="doCopy(embedURL)" class="inline-flex items-center px-3 rounded-r-md border border-black border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                    </svg>
                  </button>
                  <span id="copy-status" class="absolute text-green-600 -top-5 right-0 text-sm opacity-0">Copied!</span>
                </div>
                <ul class="text-sm m-3" :class="{'text-gray-400' : darkMode, 'text-gray-500': !darkMode}">
                  <li>Copy the URL
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline -mt-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                    </svg>
                  </li>
                  <li>In Notion add an /embed block and paste the URL</li>
                  <li>Done</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white">
    <div class="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
      <div class="px-6 py-6 bg-gray-100 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
        <div class="xl:w-0 xl:flex-1">
          <h2 class="text-2xl font-extrabold tracking-tight text-gray-700 sm:text-3xl">
            There is more to come. Get updates.
          </h2>
          <p class="mt-3 max-w-3xl text-lg leading-6 text-gray-600">
            Want to get latest updates about our <strong>Notion</strong> related features? Sign up for our Notion newsletter, we will send an email only when there is something new in relation to Notion.
          </p>
        </div>
        <div class="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
          <form
            name="notion"
            method="post"
            v-on:submit.prevent="handleSubmit"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            class="sm:flex">
            <label for="emailAddress" class="sr-only">Email address</label>
            <input v-model="formData.email" id="emailAddress" name="emailAddress" type="email" autocomplete="email" required class="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-700 focus:ring-white rounded-md" placeholder="Enter your email">
            <button type="submit" class="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">
              Subscribe
            </button>
            <input type="hidden" name="form-name" value="notion" />
            <p class="hidden">
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
          </form>

          <p class="mt-3 text-sm text-gray-600">
            We care about the protection of your data. Read our
            <router-link to="/privacy-policy" class="text-gray-700 font-medium underline">
              Privacy Policy.
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white overflow-hidden">
    <div class="relative max-w-xl mx-auto py-4 px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl text-center">
        Block examples you can steal and embed in Notion now
      </h3>
      <p class="mt-3 text-lg text-gray-500 text-center">
        Click copy, in Notion add new /embed block and paste the URL there
      </p>
      <div class="relative mt-10 space-y-4 lg:space-y-0 lg:grid lg:grid-cols-4 lg:gap-8">
        <div class="col-span-1" v-for="example in examples" v-bind:key="example">
          <component
            :is="example.type"
            :key="example.id"
            :id="example.id"
            :title="example.title"
            :size="example.size"
            :color="example.color"
            :faceType="example.faceType"
            :increaseValue="example.increaseValue"
            :elapsedTime="0"
            :totalTime="0"
            :activeSince="0"
            :activeCountdownSince="0"
            :elements="[]"
            :counters="example.counters"
            :progressStart="example.progressStart"
            :progressEnd="example.progressEnd"
            :embed="embed"
            :embedPreview="embedPreview"
            :embedOptions="embedOptions"
          />
          <div class="flex justify-between">
            <button @click="doEditExample(example)" class="mt-1 inline-flex items-center px-3 rounded-md border border-gray-100 bg-gray-50 hover:bg-gray-100 text-gray-500 sm:text-sm">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
              </svg>
              Edit
            </button>&nbsp;&nbsp;
            <button @click="doCopyExample(example)" class="mt-1 inline-flex items-center px-3 rounded-md border border-gray-100 bg-gray-50 hover:bg-gray-100 text-gray-500 sm:text-sm">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
              </svg>
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
  import {colors} from '../colors/colors.js';
  import {skins} from '../colors/skins.js';
  import {v4 as uuidv4} from "uuid";
  import LitepieDatepicker from "litepie-datepicker";
  import BlockFace from './BlockFace.vue';
  import Block from '@/components/Block.vue';
  import BlockCounter from '@/components/BlockCounter.vue';
  import BlockCountdown from '@/components/BlockCountdown.vue';
  import BlockProgress from '@/components/BlockProgress.vue';
  import BlockIcon from './BlockIcon.vue';
  import * as dayjs from "dayjs";
  import {ref} from "vue";
  import {copyText} from "vue3-clipboard";
  import anime from "animejs";
  import {mapGetters} from "vuex";
  import { useToast } from "vue-toastification";

  export default {
    name: 'BlockBuilder',
    components: {
      LitepieDatepicker,
      Block,
      BlockCounter,
      BlockCountdown,
      BlockProgress,
      BlockFace,
      BlockIcon
    },
    setup() {
      const formatter = ref({
        date: 'DD MMM YYYY HH:mm',
        month: 'MMM'
      });
      const doCopy = (url) => {
        copyText(url, undefined, (error) => {
          if (!error) {
            anime({
              targets: '#copy-status',
              opacity: [{value: 100}, {value: 0}],
              easing: 'easeInOutSine',
              duration: 1200
            });
          }
        })
      };
      const toast = useToast();
      return {
        formatter, doCopy, toast
      };
    },
    data() {
      return {
        type: 'Block',
        formData: {},
        id: uuidv4(),
        title: 'Block title',
        size: 2,
        color: 'blue',
        skin: 'neutral',
        faceType: 'default',
        increaseValue: 1,
        iconEdited: null,
        progressStart: new Date().getTime(),
        progressEnd: null,
        counters: [],
        blockTick: new Date().getTime() + 1000,
        activeSince: new Date().getTime() - 1000,
        example: null,
        darkMode: false,
        embed: true,
        embedPreview: true,
        embedOptions: {
          mode: 'update'
        },
        counterNew: {
          title: '',
          type: 'number',
          day: 0,
          icon: 'default'
        },
        counterTypes: [
          {text: 'Number', value: 'number'},
          {text: 'Date', value: 'date'},
        ],
        limits:
          {
            faces: [
                "default",
                "bear",
                "hearts",
                "argh"
            ],
            icons: [
              "default",
            ],
            colors: [
              "gray",
              "red",
              "blue",
              "green",
              "amber"
            ],
          }
        ,
        typeOptions: [
          {text: 'Time Block', value: 'Block'},
          {text: 'Habit/Goal Block', value: 'BlockCounter'},
          {text: 'Countdown Block', value: 'BlockCountdown'},
          {text: 'Progress Block', value: 'BlockProgress'},
        ],
        sizeOptionsHours: [
          {text: '00', value: 0},
          {text: '01', value: 1},
          {text: '02', value: 2},
          {text: '03', value: 3},
          {text: '04', value: 4},
          {text: '05', value: 5},
          {text: '06', value: 6},
          {text: '07', value: 7},
          {text: '08', value: 8}
        ],
        sizeOptionsMinutes: [
          {text: '00', value: 0},
          {text: '15', value: 0.25},
          {text: '30', value: 0.5},
          {text: '45', value: 0.75}
        ],
        sizeOptionsSeconds: [
          {text: '00', value: 0},
          {text: '05', value: 5},
          {text: '10', value: 10},
          {text: '15', value: 15},
          {text: '20', value: 20},
          {text: '25', value: 25},
          {text: '30', value: 30},
          {text: '35', value: 35},
          {text: '40', value: 40},
          {text: '45', value: 45},
          {text: '50', value: 50},
          {text: '55', value: 55}
        ],
        faces: [
          {text: 'default', value: 'default'},
          {text: 'cat', value: 'cat'},
          {text: 'bear', value: 'bear'},
          {text: 'owl', value: 'owl'},
          {text: 'humanbear', value: 'humanbear'},
          {text: 'hearts', value: 'hearts'},
          {text: 'money', value: 'money'},
          {text: 'argh', value: 'argh'},
          {text: 'pumpkin', value: 'pumpkin'}
        ],
        icons: [
          {text: 'default', value: 'default'},
          {text: 'flex', value: 'flex'},
          {text: 'money', value: 'money'},
          {text: 'coffee', value: 'coffee'},
          {text: 'death', value: 'death'},
          {text: 'happy', value: 'happy'},
          {text: 'headboom', value: 'headboom'},
          {text: 'sad', value: 'sad'},
          {text: 'cow', value: 'cow'},
          {text: 'star', value: 'star'},
          {text: 'beer', value: 'beer'},
          {text: 'radioactive', value: 'radioactive'},
          {text: 'banana', value: 'banana'},
          {text: 'rainbow', value: 'rainbow'},
          {text: 'toiletpaper', value: 'toiletpaper'},
          {text: 'phone', value: 'phone'},
          {text: 'donut', value: 'donut'},
          {text: 'book', value: 'book'},
          {text: 'tea', value: 'tea'},
          {text: 'wine', value: 'wine'},
          {text: 'cocktail', value: 'cocktail'}
        ],
        examples: [
          {
            "type": "BlockCounter",
            "id": "2904fad0-d584-4025-9461-94a2a1d44656",
            "title": "Water",
            "size": 2200,
            "color": "blue",
            "faceType": "default",
            "increaseValue": 50,
            "progressStart": 1627400300886,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCounter",
            "id": "bf28cbd3-ec55-480f-b2ec-6ce5dd71e5aa",
            "title": "Exercise daily",
            "size": 1,
            "color": "amber",
            "faceType": "flex",
            "increaseValue": 1,
            "progressStart": 1627400300886,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCounter",
            "id": "0354ba40-4975-4fb8-93fc-77562f8fbec8",
            "title": "Read 10 pages",
            "size": 10,
            "color": "green",
            "faceType": "book",
            "increaseValue": 1,
            "progressStart": 1627400300886,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCounter",
            "id": "268c0173-c411-47e3-be71-dd7da76ed884",
            "title": "Coffee",
            "size": 3,
            "color": "gray",
            "faceType": "coffee",
            "increaseValue": 1,
            "progressStart": 1627400300886,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCounter",
            "id": "8b1e04c1-b4c3-40f5-8c7c-cc0cc51305cc",
            "title": "Pomodoro",
            "size": 6,
            "color": "red",
            "faceType": "happy",
            "increaseValue": 1,
            "progressStart": 1627400300886,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCountdown",
            "id": "5a4f8aab-9b42-4183-8303-67a279ae98be",
            "title": "Pomodoro",
            "size": 3120,
            "color": "red",
            "faceType": "cow",
            "increaseValue": 1,
            "progressStart": 1627400300886,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCountdown",
            "id": "eb95c800-f48d-46d6-9ac9-b589d21f25c8",
            "title": "5 minutes break",
            "size": 300,
            "color": "bluegray",
            "faceType": "banana",
            "increaseValue": 1,
            "progressStart": 1627400300886,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCountdown",
            "id": "14ffc874-227e-4302-bb2f-681939086208",
            "title": "25 minutes break",
            "size": 1500,
            "color": "bluegray",
            "faceType": "donut",
            "progressStart": 1627400300886,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCounter",
            "id": "ef1c7f83-df89-4b63-ba05-a52d3366a465",
            "title": "Run 10km weekly",
            "size": 10,
            "color": "green",
            "faceType": "flex",
            "increaseValue": 1,
            "progressStart": 1627401809601,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCounter",
            "id": "e2068a32-2c3f-44ad-92cf-374540b5a062",
            "title": "Stretch",
            "size": 5,
            "color": "yellow",
            "faceType": "flex",
            "increaseValue": 1,
            "progressStart": 1627401445384,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCounter",
            "id": "e83f49d8-4d29-4735-9143-d6ad9f788594",
            "title": "Appreciate",
            "size": 1,
            "color": "orange",
            "faceType": "star",
            "increaseValue": 1,
            "progressStart": 1627401445384,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockCounter",
            "id": "340f27bd-8c21-45f3-bde9-d24c755b3328",
            "title": "Call a Friend",
            "size": 1,
            "color": "fuchsia",
            "faceType": "phone",
            "increaseValue": 1,
            "progressStart": 1627401445384,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockProgress",
            "id": "facde659-2c53-4424-a004-34fb764a4e4a",
            "title": "My Twitter age",
            "size": 2,
            "color": "lightblue",
            "faceType": "default",
            "increaseValue": 1,
            "progressStart": 1320426300000,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockProgress",
            "id": "a8bb8a15-8361-485d-accc-96e6099fc7c7",
            "title": "I am this young",
            "size": 2,
            "color": "bluegray",
            "faceType": "star",
            "increaseValue": 1,
            "progressStart": 454522320000,
            "progressEnd": null,
            "counters": [
              {
                "title": "Days on the planet",
                "type": "number",
                "day": "1",
                "icon": "default"
              }
            ]
          },
          {
            "type": "Block",
            "id": "f70ef56e-fcd7-40c2-bd08-152f640d7105",
            "title": "Write one hour",
            "size": 1,
            "color": "lightblue",
            "faceType": "default",
            "increaseValue": 1,
            "progressStart": 1627402436317,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "Block",
            "id": "e1e6447e-2407-418d-8c81-a828f0f865bc",
            "title": "Focus",
            "size": 2,
            "color": "green",
            "faceType": "default",
            "increaseValue": 1,
            "progressStart": 1627402436317,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "BlockProgress",
            "id": "8a891821-b9ae-47d7-8f2c-4a7cc5dafbfd",
            "title": "Alcohol Free",
            "size": 2,
            "color": "blue",
            "faceType": "default",
            "increaseValue": 1,
            "progressStart": 1587658200000,
            "progressEnd": null,
            "counters": [
              {
                "title": "Money saved",
                "type": "number",
                "day": "6",
                "icon": "money"
              },
              {
                "title": "Drinks dodged",
                "type": "number",
                "day": "2",
                "icon": "cocktail"
              }
            ]
          },
          {
            "type": "BlockProgress",
            "id": "d1b0b53e-6901-4073-b325-950c86a9a7e7",
            "title": "Smoke Free one year",
            "size": 2,
            "color": "red",
            "faceType": "default",
            "increaseValue": 1,
            "progressStart": 1617293100000,
            "progressEnd": 1648829100000,
            "counters": [
              {
                "title": "Money saved",
                "type": "number",
                "day": 6,
                "icon": "money"
              },
              {
                "title": "Life regained",
                "type": "date",
                "day": 120,
                "icon": "rainbow"
              },
              {
                "title": "Cigarettes not smoked",
                "type": "number",
                "day": 25,
                "icon": "headboom"
              }
            ]
          },
          {
            "type": "Block",
            "id": "fe231909-3496-4c1b-87a8-f366e20ea638",
            "title": "Side project #1",
            "size": 3,
            "color": "amber",
            "faceType": "default",
            "increaseValue": 1,
            "progressStart": 1627402436317,
            "progressEnd": null,
            "counters": []
          },
          {
            "type": "Block",
            "id": "aa05a4a8-7ebf-47d6-b4fd-38704856a042",
            "title": "Work",
            "size": 4,
            "color": "fuchsia",
            "faceType": "default",
            "increaseValue": 1,
            "progressStart": 1627402436317,
            "progressEnd": null,
            "counters": []
          }
        ],
        colors,
        skins
      }
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      blockSizeHours: {
        get() {
          return ~~this.size;
        },
        set(v) {
          this.size = v + this.blockSizeMinutes;
        }
      },
      blockSizeMinutes: {
        get() {
          if (this.type === 'BlockCountdown') {
            let value = this.size / 60;
            return ~~value;
          } else {
            return this.size - Math.floor(this.size);
          }
        },
        set(v) {
          if (this.type === 'BlockCountdown') {
            this.size = (v * 60) + this.blockSizeSeconds;
          } else {
            this.size = this.blockSizeHours + v;
          }
        }
      },
      blockSizeSeconds: {
        get() {
          let value = this.size / 60;
          return this.size - (~~value * 60);
        },
        set(v) {
          this.size = (this.blockSizeMinutes * 60) + v;
        }
      },
      progressStartCal: {
        get() {
          return dayjs(this.progressStart).format('DD MMM YYYY HH:mm');
        },
        set(v) {
          const progressTS = dayjs(v, 'DD MMM YYYY HH:mm').valueOf();
          this.progressStart = JSON.parse(JSON.stringify(progressTS));
        }
      },
      progressEndCal: {
        get() {
          return dayjs(this.progressEnd).format('DD MMM YYYY HH:mm');
        },
        set(v) {
          const progressTS = dayjs(v, 'DD MMM YYYY HH:mm').valueOf();
          this.progressEnd = JSON.parse(JSON.stringify(progressTS));
        }
      },
      embedURL() {
        return this.createEmbedURL(this);
      },
      blockObj() {
        return {
          type: this.type,
          id: uuidv4(),
          title: this.title,
          size: this.size,
          color: this.color,
          skin: this.skin,
          faceType: this.faceType,
          increaseValue: this.increaseValue,
          progressStart: this.progressStart,
          progressEnd: this.progressEnd,
          counters: this.counters
        }
      }
    },
    watch: {
      type(newType) {
        // reset values
        if (this.example) {
          this.example = null;
        } else {
          if (newType === 'BlockProgress') {
            this.size = 2;
          } else if (newType === 'BlockCounter') {
            this.size = 2;
          } else if (newType === 'BlockCountdown') {
            this.size = 120;
          } else {
            this.size = 2;
          }
        }
        this.id = uuidv4();
      },
      progressStart() {
        this.blockTick = new Date().getTime() + 1000;
      },
      progressEnd() {
        this.blockTick = new Date().getTime() + 1000;
      }
    },
    methods: {
      blockChangeColor(color) {
        this.color = color;
      },
      blockChangeSkin(skin) {
        this.skin = skin;
      },
      blockChangeFace(face) {
        this.faceType = face;
      },
      counterChangeIconOpen(index) {
        if (this.iconEdited === index) {
          this.iconEdited = null;
        } else {
          this.iconEdited = index;
        }
      },
      counterChangeIcon(index, icon) {
        if (index === 'new') {
          this.counterNew.icon = icon;
        } else {
          this.counters[index].icon = icon;
        }
        this.iconEdited = null;
      },
      counterDelete(index) {
        this.counters.splice(index, 1);
      },
      counterAdd() {
        this.counters.push(
          {
            title: this.counterNew.title,
            type: this.counterNew.type,
            day: this.counterNew.day,
            icon: this.counterNew.icon
          }
        );
        this.counterNew.title = '';
        this.counterNew.type = 'number';
        this.counterNew.day = 0;
        this.counterNew.icon = 'default';
      },
      createEmbedURL(block) {
        let params = [
          {name: 'title', value: block.title},
          {name: 'type', value: block.type },
          {name: 'color', value: block.color },
          {name: 'size', value: block.size },
          {name: 'faceType', value: block.faceType }
        ];

        if (this.darkMode) {
          params.push({name: 'darkMode', value: 'true' });
        }

        if (block.faceType === 'humanbear') {
          params.push({name: 'skin', value: block.skin });
        }

        if (block.type === 'BlockCounter') {
          params.push({name: 'increaseValue', value: block.increaseValue });
        } else if (block.type === 'BlockProgress') {
          params.push({name: 'progressStart', value: block.progressStart});
          params.push({name: 'progressEnd', value: block.progressEnd});
          params.push({name: 'counters', value: JSON.stringify(block.counters)});
        }

        const parts = params.map((param) => {
          return(
              encodeURIComponent(param.name) + '=' +
              encodeURIComponent(param.value)
          );
        });

        const url = parts.join('&');

        return 'https://getkairo.com/embed-local?id=' + block.id + '&local=true&' + url;
      },
      doCopyExample(block) {
        this.doCopy(this.createEmbedURL(block));
      },
      doEditExample(block) {
        this.example = true;
        this.type = block.type;
        this.title = block.title;
        this.size = block.size;
        this.color = block.color;
        this.faceType = block.faceType;
        this.increaseValue = block.increaseValue;
        this.progressStart = block.progressStart;
        this.progressEnd = block.progressEnd;
        this.counters = block.counters;
        this.blockTick = new Date().getTime() + 1000;
        this.activeSince = new Date().getTime() - 1000;

        document.querySelector('#block-builder').scrollIntoView({
          behavior: 'smooth'
        });
      },
      limitFilter(type, value) {
        return this.limits[type].filter(n => n===value).length===0 ? false : true;
      },
      encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
      },
      handleSubmit(e) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: this.encode({
            'form-name': e.target.getAttribute('name'),
            ...this.formData,
          }),
        })
            .then(() => this.toast.success(`Thank you for being part of our journey! We will be in touch.`))
            .catch(() => this.toast.error(`Something went wrong. Please try again. :(`))
      },
    },
    mounted () {
    },
  }
</script>

<style>
  .embed-dark-mode {
    background-color: #2f3437;
  }
</style>
